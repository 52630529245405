import request from 'utils/request';

export const api = {
  DOWNLOAD_BILL: '/api/affiliate/billing/downloadExcel',
  AFF_BILLING_REPORT: '/api/affiliate/billing/report',
  BATCH_CHANGE_STATUS: '/api/affiliate/billing/batchChangeStatus',
};

export function getAffBillingReport(query) {
  return request({
    url: api.AFF_BILLING_REPORT,
    method: 'get',
    params: query,
  });
}

export function batchChangeStatus(data) {
  return request({
    url: api.BATCH_CHANGE_STATUS,
    method: 'put',
    data,
  });
}
